import { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import { useContext, useEffect } from "react"
import { ChallengesContext } from "../../../controllers/challenges"
import { AuthContext } from "../../../controllers/auth"
import { Grid, Stack, Typography } from "@mui/material"
import PastChallengeCard from "../../../components/challenge/PastChallengeCard"
import FadeFromTop from "../../../components/animations/FadeFromTop"

const Challenges = () => {
  const { team } = useContext(AuthContext)
  const { challengesDataLoading, getChallengesData, pastChallengesList } =
    useContext(ChallengesContext)

  // get data
  useEffect(() => {
    getChallengesData()
  }, [team])

  return (
    <PageContainer
      title={t("challenges")}
      breadcrumbs={[{ title: t("challenges") }]}
      withFilters={false}
      withDatePicker={false}
    >
      <FadeFromTop>
        {!challengesDataLoading && !pastChallengesList.length ? (
          <Stack>
            <Typography>{t("no_challenges_for_team")}</Typography>
          </Stack>
        ) : (
          <Grid container spacing={3} columns={2}>
            {challengesDataLoading
              ? Array(4)
                  .fill("")
                  .map((_, index) => (
                    <Grid
                      key={index}
                      item
                      sm={2}
                      md={1}
                      style={{ width: "100%" }}
                    >
                      <PastChallengeCard />
                    </Grid>
                  ))
              : pastChallengesList.map((pastChallenge) => (
                  <Grid
                    key={pastChallenge.id}
                    item
                    sm={2}
                    md={1}
                    style={{ width: "100%" }}
                  >
                    <PastChallengeCard challenge={pastChallenge} />
                  </Grid>
                ))}
          </Grid>
        )}
      </FadeFromTop>
    </PageContainer>
  )
}

export default Challenges
