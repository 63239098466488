import { createContext, ReactNode, useContext, useState } from "react"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import { t } from "i18next"
import { logger, Status } from "../services/utils/utils"
import { useLazyQuery } from "@apollo/client"
import {
  dashboardTopSdgsList,
  dashboardTopCategoriesList,
  dashboardCompletedEpisodesList,
  dashboardEpisodePerformanceList,
  dashboardTopContentList,
  dashboardEducationHoursGet,
  dashboardTotalQuizGet,
  dashboardTotalStoryGet,
} from "../services/graphql/queries"
import SdgItem from "../models/sdgItem"
import CategoryItem from "../models/categoryItem"
import EpisodeItem from "../models/episodeItem"
import ActionItem from "../models/actionItem"
import CompletedEpisodesCardDataItem from "../models/completedEpisodesCardDataItem"
import EducationHoursByTimeListItem from "../models/educationHoursByTimeListItem"

interface EducationContextInterface {
  educationDataLoading: boolean
  topSdgsList: SdgItem[]
  topCategoriesList: CategoryItem[]
  topEpisodesList: EpisodeItem[]
  topEpisodesListNextToken: string | null
  topActionsList: ActionItem[]
  topActionsListNextToken: string | null
  completedEpisodesCardData: CompletedEpisodesCardDataItem[]
  educationHoursByTimeList: EducationHoursByTimeListItem[]
  totalQuizzes: {
    total: number
    totalSuccess: number
  } | null
  totalEpisodes: {
    total: number
    totalSuccess: number
  } | null
  loadMoreTopEpisodesItems: () => Promise<boolean>
  loadMoreTopActionsItems: () => Promise<boolean>
  getEducationData: () => void
}

const EducationContext = createContext<EducationContextInterface>({
  educationDataLoading: true,
  topSdgsList: [],
  topCategoriesList: [],
  topEpisodesList: [],
  topEpisodesListNextToken: null,
  topActionsList: [],
  topActionsListNextToken: null,
  completedEpisodesCardData: [],
  educationHoursByTimeList: [],
  totalQuizzes: null,
  totalEpisodes: null,
  loadMoreTopEpisodesItems: async () => true,
  loadMoreTopActionsItems: async () => true,
  getEducationData: () => {},
})

const EducationController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { getInput, setError, setErrorMessage } = useContext(MainContext)

  // states
  const [educationDataLoading, setEducationDataLoading] =
    useState<boolean>(false)
  const [topSdgsList, setTopSdgsList] = useState<SdgItem[]>([])
  const [topCategoriesList, setTopCategoriesList] = useState<CategoryItem[]>([])
  const [topEpisodesList, setTopEpisodesList] = useState<EpisodeItem[]>([])
  const [topEpisodesListNextToken, setTopEpisodesListNextToken] = useState<
    string | null
  >(null)
  const [topActionsList, setTopActionsList] = useState<ActionItem[]>([])
  const [topActionsListNextToken, setTopActionsListNextToken] = useState<
    string | null
  >(null)
  const [completedEpisodesCardData, setCompletedEpisodesCardData] = useState<
    CompletedEpisodesCardDataItem[]
  >([])
  const [educationHoursByTimeList, setEducationHoursByTimeList] = useState<
    EducationHoursByTimeListItem[]
  >([])
  const [totalQuizzes, setTotalQuizzes] = useState<{
    total: number
    totalSuccess: number
  } | null>(null)
  const [totalEpisodes, setTotalEpisodes] = useState<{
    total: number
    totalSuccess: number
  } | null>(null)

  // queries
  const [dashboardTopSdgsListQuery] = useLazyQuery(dashboardTopSdgsList)
  const [dashboardTopCategoriesListQuery] = useLazyQuery(
    dashboardTopCategoriesList
  )
  const [dashboardCompletedEpisodesListQuery] = useLazyQuery(
    dashboardCompletedEpisodesList
  )
  const [dashboardEpisodePerformanceListQuery] = useLazyQuery(
    dashboardEpisodePerformanceList
  )
  const [dashboardTopContentListQuery] = useLazyQuery(dashboardTopContentList)
  const [dashboardEducationHoursGetQuery] = useLazyQuery(
    dashboardEducationHoursGet
  )
  const [dashboardTotalQuizGetQuery] = useLazyQuery(dashboardTotalQuizGet)
  const [dashboardTotalStoryGetQuery] = useLazyQuery(dashboardTotalStoryGet)

  // get top cards
  const getTopCardsData = async () => {
    try {
      logger(Status.Api, "[EDU] QUERY topSdgsList")
      logger(Status.Api, "[EDU] QUERY topCategoriesList")
      logger(Status.Api, "[EDU] QUERY episodePerformanceList")
      logger(Status.Api, "[EDU] QUERY topContentList")

      const input = getInput(team!.id)

      const result = await Promise.all([
        dashboardTopSdgsListQuery({
          variables: { input: { ...input, limit: 100 } },
        }),
        dashboardTopCategoriesListQuery({
          variables: { input: { ...input, limit: 100 } },
        }),
        dashboardEpisodePerformanceListQuery({
          variables: {
            input: { ...input, limit: 50 },
          },
        }),
        dashboardTopContentListQuery({
          variables: {
            input: { ...input, limit: 50, activityType: "action" },
          },
        }),
      ])
      logger(
        Status.Info,
        "[EDU] top sdgs list",
        result[0].data.dashboardTopSdgsList.items
      )
      logger(
        Status.Info,
        "[EDU] top categories list",
        result[1].data.dashboardTopCategoriesList.items
      )
      logger(
        Status.Info,
        "[EDU] top episodes list",
        result[2].data.dashboardEpisodePerformanceList.items
      )
      logger(
        Status.Info,
        "[EDU] top actions list",
        result[3].data.dashboardTopContentList.items
      )

      setTopSdgsList(result[0].data.dashboardTopSdgsList.items)
      setTopCategoriesList(result[1].data.dashboardTopCategoriesList.items)
      setTopEpisodesList(result[2].data.dashboardEpisodePerformanceList.items)
      setTopEpisodesListNextToken(
        result[2].data.dashboardEpisodePerformanceList.nextToken
      )
      setTopActionsList(result[3].data.dashboardTopContentList.items)
      setTopActionsListNextToken(
        result[3].data.dashboardTopContentList.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} SDGs / Categories / Content`)
      setTopSdgsList([])
      setTopCategoriesList([])
      setTopEpisodesList([])
      setTopEpisodesListNextToken(null)
      setTopActionsList([])
      setTopActionsListNextToken(null)

      return false
    }
  }

  // get completed episodes card
  const getCompletedEpisodesCardData = async () => {
    try {
      logger(Status.Api, "[EDU] QUERY completedEpisodesList")

      const input = getInput(team!.id)

      const { data } = await dashboardCompletedEpisodesListQuery({
        variables: { input: { ...input, limit: 50 } },
      })
      logger(
        Status.Info,
        "[EDU] completed episodes card data",
        data.dashboardCompletedEpisodesList.items
      )

      setCompletedEpisodesCardData(data.dashboardCompletedEpisodesList.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Completed Stories`)
      setCompletedEpisodesCardData([])

      return false
    }
  }

  // get education hours by time list
  const getEducationHoursByTimeList = async () => {
    try {
      logger(Status.Api, "[EDU] QUERY educationHoursGet")

      const input: any = getInput(team!.id)
      delete input.lang

      const { data } = await dashboardEducationHoursGetQuery({
        variables: { input: { ...input, limit: 1000 } },
      })
      logger(
        Status.Info,
        "[EDU] education hours by time list",
        data.dashboardEducationHoursGet.items
      )

      setEducationHoursByTimeList(data.dashboardEducationHoursGet.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Education Hours By Time`)
      setEducationHoursByTimeList([])

      return false
    }
  }

  // get total quizzes
  const getTotalQuizzes = async () => {
    try {
      logger(Status.Api, "[EDU] QUERY totalQuizGet")

      const input = getInput(team!.id)

      const { data } = await dashboardTotalQuizGetQuery({
        variables: { input },
      })

      const dataToSet = {
        total: data.dashboardTotalQuizGet.totalQuiz,
        totalSuccess: data.dashboardTotalQuizGet.totalQuizSuccess,
      }
      logger(Status.Info, "[EDU] total quizzes", dataToSet)

      setTotalQuizzes(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Total Quizzes`)
      setTotalQuizzes(null)

      return false
    }
  }

  // get total episodes
  const getTotalEpisodes = async () => {
    try {
      logger(Status.Api, "[EDU] QUERY totalStoryGet")

      const input = getInput(team!.id)

      const { data } = await dashboardTotalStoryGetQuery({
        variables: { input },
      })

      const dataToSet = {
        total: data.dashboardTotalStoryGet.totalStory,
        totalSuccess: data.dashboardTotalStoryGet.totalStorySuccess,
      }
      logger(Status.Info, "[EDU] total episodes", dataToSet)

      setTotalEpisodes(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Total Stories`)
      setTotalEpisodes(null)

      return false
    }
  }

  // load more episodes
  const loadMoreTopEpisodesItems = async () => {
    try {
      logger(Status.Api, "QUERY episodePerformanceList")

      const input = getInput(team!.id)

      const { data } = await dashboardEpisodePerformanceListQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            nextToken: topEpisodesListNextToken,
          },
        },
      })
      logger(Status.Info, "top episodes list", [
        ...topEpisodesList,
        ...data.dashboardEpisodePerformanceList.items,
      ])

      setTopEpisodesList([
        ...topEpisodesList,
        ...data.dashboardEpisodePerformanceList.items,
      ])
      setTopEpisodesListNextToken(
        data.dashboardEpisodePerformanceList.nextToken
      )

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Stories`)

      return false
    }
  }

  // load more actions
  const loadMoreTopActionsItems = async () => {
    try {
      logger(Status.Api, "QUERY topContentList")

      const input = getInput(team!.id)

      const { data } = await dashboardTopContentListQuery({
        variables: {
          input: {
            ...input,
            limit: 50,
            activityType: "action",
            nextToken: topActionsListNextToken,
          },
        },
      })
      logger(Status.Info, "top actions list", [
        ...topActionsList,
        ...data.dashboardTopContentList.items,
      ])

      setTopActionsList([
        ...topActionsList,
        ...data.dashboardTopContentList.items,
      ])
      setTopActionsListNextToken(data.dashboardTopContentList.nextToken)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Actions`)

      return false
    }
  }

  // get all data
  const getEducationData = async () => {
    setEducationDataLoading(true)

    await Promise.all([
      getTopCardsData(),
      getCompletedEpisodesCardData(),
      getEducationHoursByTimeList(),
      getTotalQuizzes(),
      getTotalEpisodes(),
    ])

    setEducationDataLoading(false)
  }

  return (
    <EducationContext.Provider
      value={{
        educationDataLoading,
        topSdgsList,
        topCategoriesList,
        topEpisodesList,
        topEpisodesListNextToken,
        topActionsList,
        topActionsListNextToken,
        completedEpisodesCardData,
        educationHoursByTimeList,
        totalQuizzes,
        totalEpisodes,
        loadMoreTopEpisodesItems,
        loadMoreTopActionsItems,
        getEducationData,
      }}
    >
      {children}
    </EducationContext.Provider>
  )
}
export { EducationController, EducationContext }
