import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { colors } from "../../services/config/colors"
import LineChartSelectable from "../global/LineChartSelectable"
import { MainContext } from "../../controllers/main"
import { t } from "i18next"
import months from "../../services/config/months"
import { calculateDaysBetweenDates } from "../../services/utils/utils"
import ChallengeOverviewDataItem from "../../models/challengeOverviewDataItem"
import { OverviewContext } from "../../controllers/overview"

const ChallengeOverviewLineChart = ({
  selectedItem,
  interval,
  dataForChart,
  setDataForChart,
  selectedOption,
  loading,
}: {
  selectedItem: number
  interval: {
    start: Date
    end: Date
  }
  dataForChart: ChallengeOverviewDataItem[]
  setDataForChart: Dispatch<SetStateAction<ChallengeOverviewDataItem[]>>
  selectedOption: number
  loading: boolean
}) => {
  const { dateRange } = useContext(MainContext)
  const { challengeOverviewData, challenge } = useContext(OverviewContext)

  // show chart after a delay to avoid lag
  const [showChart, setShowChart] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowChart(true)
    }, 350)
  }, [])

  // filtered data based on interval and dateRange
  useEffect(() => {
    const filteredData = challengeOverviewData.filter(
      (item) =>
        new Date(item.activityDate!) >=
          new Date(interval.start.toISOString().split("T")[0]) &&
        new Date(item.activityDate!) <=
          new Date(interval.end.toISOString().split("T")[0])
    )

    const startDate = new Date(interval.start)
    const allDates: Date[] = []
    do {
      allDates.push(new Date(startDate))
      startDate.setDate(startDate.getDate() + 1)
    } while (startDate <= interval.end)

    const dataToSet: any[] = []
    allDates.forEach((date) => {
      if (
        (dateRange.start &&
          new Date(date.toISOString().split("T")[0]) <
            new Date(dateRange.start.toISOString().split("T")[0])) ||
        (dateRange.end &&
          new Date(date.toISOString().split("T")[0]) >
            new Date(dateRange.end.toISOString().split("T")[0])) ||
        date > new Date() ||
        date.toDateString() === new Date().toDateString()
      ) {
        const dateToSet = new Date(date.toISOString().split("T")[0])
        dataToSet.push({
          activityAmount: null,
          activityDate: `${dateToSet.getDate()} ${t(
            months[dateToSet.getMonth()]
          )} ${dateToSet.getFullYear()}`,
        })
      } else {
        const itemToPush = filteredData.find(
          (item) =>
            new Date(item.activityDate!).toDateString() === date.toDateString()
        )
        if (itemToPush) {
          const dateToSet = new Date(itemToPush.activityDate)
          dataToSet.push({
            ...itemToPush,
            activityDate: `${dateToSet.getDate()} ${t(
              months[dateToSet.getMonth()]
            )} ${dateToSet.getFullYear()}`,
          })
        } else {
          const dateToSet = new Date(date.toISOString().split("T")[0])
          dataToSet.push({
            activityAmount: 0,
            activityDate: `${dateToSet.getDate()} ${t(
              months[dateToSet.getMonth()]
            )} ${dateToSet.getFullYear()}`,
          })
        }
      }
    })

    // aggregate data
    if (selectedOption === 3) {
      const monthsToAggregate: string[] = []
      dataToSet.forEach((item) => {
        const monthAndYear = item.activityDate.slice(
          item.activityDate.indexOf(" ") + 1
        )

        if (!monthsToAggregate.includes(monthAndYear)) {
          monthsToAggregate.push(monthAndYear)
        }
      })

      const aggregatedData = []

      for (let i = 0; i < monthsToAggregate.length; i++) {
        aggregatedData.push(
          dataToSet
            .filter((item) =>
              item.activityDate.includes(t(monthsToAggregate[i]))
            )
            .reduce(
              (accumulator, item, index, array) => {
                if (accumulator.activityAmount !== null) {
                  accumulator.activityAmount += item.activityAmount ?? 0
                } else {
                  accumulator.activityAmount = item.activityAmount
                }

                accumulator.avgActivitiesDeltaPercentage +=
                  item.avgActivitiesDeltaPercentage

                if (index === array.length - 1) {
                  accumulator.avgActivitiesDeltaPercentage /= index
                }

                return accumulator
              },
              {
                activityAmount: null,
                activityDate: monthsToAggregate[i],
              }
            )
        )
      }

      setDataForChart(aggregatedData)
    } else if (calculateDaysBetweenDates(interval.start, interval.end) >= 360) {
      const aggregatedData = []

      for (let i = 0; i < 12; i++) {
        aggregatedData.push(
          dataToSet
            .filter((item) => item.activityDate.includes(t(months[i])))
            .reduce(
              (accumulator, item, index, array) => {
                if (accumulator.activityAmount !== null) {
                  accumulator.activityAmount += item.activityAmount ?? 0
                } else {
                  accumulator.activityAmount = item.activityAmount
                }

                accumulator.avgActivitiesDeltaPercentage +=
                  item.avgActivitiesDeltaPercentage

                if (index === array.length - 1) {
                  accumulator.avgActivitiesDeltaPercentage /= index
                }

                return accumulator
              },
              {
                activityAmount: null,
                activityDate: `${t(months[i])} ${interval.start.getFullYear()}`,
              }
            )
        )
      }

      setDataForChart(aggregatedData)
    } else {
      setDataForChart(dataToSet)
    }
  }, [challengeOverviewData, interval, dateRange])

  return !showChart ? null : (
    <LineChartSelectable
      data={[
        {
          label: challenge?.metricName ?? "",
          data: dataForChart.map((item) => {
            return {
              label: item.activityDate,
              value: item.activityAmount,
              backgroundColor: colors.categories.episodes,
            }
          }),
        },
      ]}
      selectedDataset={selectedItem}
      loading={loading}
    />
  )
}

export default ChallengeOverviewLineChart
