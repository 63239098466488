import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import { useLazyQuery } from "@apollo/client"
import {
  dashboardChallengeGroupLeaderboardGet,
  dashboardChallengeLeaderboardGet,
  dashboardChallengeList,
} from "../services/graphql/queries"
import i18next, { t } from "i18next"
import { logger, Status } from "../services/utils/utils"
import ChallengeLeaderboardItem from "../models/challengeLeaderboardItem"
import PastChallenge from "../models/pastChallenge"
import ChallengeGroupsLeaderboardItem from "../models/challengeGroupsLeaderboardItem"

interface ChallengesContextInterface {
  challengesDataLoading: boolean
  setChallengesDataLoading: Dispatch<SetStateAction<boolean>>
  pastChallengesList: PastChallenge[]
  getPastChallengeLeaderboard: (
    teamId: string,
    challenge: string,
    nextToken?: string
  ) => Promise<{ items: ChallengeLeaderboardItem[]; nextToken?: string }>
  getPastChallengeGroupsLeaderboard: (
    teamId: string,
    challenge: string,
    nextToken?: string
  ) => Promise<{ items: ChallengeGroupsLeaderboardItem[]; nextToken?: string }>
  getChallengesData: () => void
}

const ChallengesContext = createContext<ChallengesContextInterface>({
  challengesDataLoading: true,
  setChallengesDataLoading: () => {},
  pastChallengesList: [],
  getPastChallengeLeaderboard: async () => {
    return { items: [] }
  },
  getPastChallengeGroupsLeaderboard: async () => {
    return { items: [] }
  },
  getChallengesData: () => {},
})

const ChallengesController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { setError, setErrorMessage, getInput } = useContext(MainContext)

  // states
  const [challengesDataLoading, setChallengesDataLoading] =
    useState<boolean>(false)
  const [pastChallengesList, setPastChallengesList] = useState<PastChallenge[]>(
    []
  )

  // queries
  const [dashboardChallengeListQuery] = useLazyQuery(dashboardChallengeList)
  const [dashboardChallengeLeaderboardGetQuery] = useLazyQuery(
    dashboardChallengeLeaderboardGet
  )
  const [dashboardChallengeGroupLeaderboardGetQuery] = useLazyQuery(
    dashboardChallengeGroupLeaderboardGet
  )

  // get past challenges list
  const getPastChallengesList = async () => {
    try {
      logger(Status.Api, "[CHL] QUERY challengeList")

      const input = {
        teamId: team!.id,
        lang: i18next.language,
        limit: 50,
      }

      const { data } = await dashboardChallengeListQuery({
        variables: {
          input,
        },
      })

      // parse data
      const dataToSet: PastChallenge[] = data.dashboardChallengeList.items.map(
        (item: { challenge: PastChallenge }) => item.challenge
      )
      dataToSet.sort(
        (a, b) =>
          new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime()
      )

      logger(Status.Info, "[CHL] past challenges list", dataToSet)

      setPastChallengesList(dataToSet)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenges List`)
      setPastChallengesList([])

      return false
    }
  }

  // get past challenge leaderboard
  const getPastChallengeLeaderboard = async (
    teamId: string,
    challengeId: string,
    nextToken?: string
  ) => {
    try {
      logger(Status.Api, "[CHL] QUERY challengeLeaderboardGet")

      const input = getInput(teamId)

      const { data } = await dashboardChallengeLeaderboardGetQuery({
        variables: {
          input: {
            teamId,
            challengeId,
            lang: i18next.language,
            country: input.country,
            groupId: input.groupId,
            nextToken,
            limit: 50,
          },
        },
      })

      logger(
        Status.Info,
        `[CHL] past challenge ${challengeId} leaderboard`,
        data.dashboardChallengeLeaderboardGet.items
      )

      return {
        items: data.dashboardChallengeLeaderboardGet
          .items as ChallengeLeaderboardItem[],
        nextToken: data.dashboardChallengeLeaderboardGet.nextToken,
      }
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenge Leaderboard`)

      return { items: [] }
    }
  }

  // get past challenge groups leaderboard
  const getPastChallengeGroupsLeaderboard = async (
    teamId: string,
    challengeId: string,
    nextToken?: string
  ) => {
    try {
      logger(Status.Api, "[CHL] QUERY challengeGroupLeaderboardGet")

      const input = getInput(teamId)

      const { data } = await dashboardChallengeGroupLeaderboardGetQuery({
        variables: {
          input: {
            teamId,
            challengeId,
            lang: i18next.language,
            country: input.country,
            groupId: input.groupId,
            nextToken,
            limit: 50,
          },
        },
      })

      logger(
        Status.Info,
        `[CHL] past challenge ${challengeId} groups leaderboard`,
        data.dashboardChallengeGroupLeaderboardGet.items
      )

      return {
        items: data.dashboardChallengeGroupLeaderboardGet
          .items as ChallengeGroupsLeaderboardItem[],
        nextToken: data.dashboardChallengeGroupLeaderboardGet.nextToken,
      }
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Past Challenge Groups Leaderboard`)

      return { items: [] }
    }
  }

  // get all data
  const getChallengesData = async () => {
    setChallengesDataLoading(true)

    await Promise.all([getPastChallengesList()])

    setChallengesDataLoading(false)
  }

  return (
    <ChallengesContext.Provider
      value={{
        challengesDataLoading,
        setChallengesDataLoading,
        pastChallengesList,
        getPastChallengeLeaderboard,
        getPastChallengeGroupsLeaderboard,
        getChallengesData,
      }}
    >
      {children}
    </ChallengesContext.Provider>
  )
}
export { ChallengesController, ChallengesContext }
