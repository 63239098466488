import { useContext, useEffect } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { ActivitiesContext } from "../../../controllers/activities"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import Card from "../../../components/global/Card"
import { Stack } from "@mui/material"
import Title from "../../../components/global/Title"
import Text from "../../../components/global/Text"
import { colors } from "../../../services/config/colors"
import Select from "../../../components/global/Select"
import LineChart from "../../../components/global/LineChart"
import months from "../../../services/config/months"
import { AuthContext } from "../../../controllers/auth"
import { formatNumber } from "../../../services/utils/utils"

const Activities = () => {
  const { team } = useContext(AuthContext)
  const {
    activitiesDataLoading,
    getActivitiesData,
    activitiesList,
    activitiesListOptions,
    activitiesListSelectedOption,
    setActivitiesListSelectedOption,
    missionsList,
    missionsListOptions,
    missionsListSelectedOption,
    setMissionsListSelectedOption,
    paymentsList,
  } = useContext(ActivitiesContext)

  // get data
  useEffect(() => {
    getActivitiesData()
  }, [team, activitiesListSelectedOption, missionsListSelectedOption])

  return (
    <PageContainer
      title={t("activities_and_missions")}
      breadcrumbs={[{ title: t("activities_and_missions") }]}
      infoText={t("activities_and_missions_info")}
    >
      <FadeFromTop>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("overview_overtime")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 69,
                position: "relative",
              }}
            >
              <Title loading={activitiesDataLoading}>
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {formatNumber(
                    activitiesList.reduce(
                      (total, item) =>
                        total + parseInt(item.activityAmount as any),
                      0
                    )
                  )}
                </span>
              </Title>
              <Stack style={{ position: "absolute", right: 0, gap: 6 }}>
                <Text
                  fontSize={14}
                  lineHeight="14px"
                  fontWeight={500}
                  color={colors.primary}
                >
                  {t("show")}
                </Text>
                <Select
                  options={activitiesListOptions.map((option) => option.label)}
                  selectedOption={activitiesListSelectedOption}
                  onChange={(newValue) =>
                    setActivitiesListSelectedOption(newValue)
                  }
                  popoverWidth={160}
                  style={{ width: 160 }}
                  loading={activitiesDataLoading}
                />
              </Stack>
            </Stack>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("activities"),
                      data: activitiesList.map((item) => {
                        const dateToSet = new Date(item.activityDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.activityAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={activitiesDataLoading}
                  pointRadius={1.9}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
      <FadeFromTop delay={0.05} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("missions_overview")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 69,
                position: "relative",
              }}
            >
              <Title loading={activitiesDataLoading}>
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {formatNumber(
                    missionsList.reduce(
                      (total, item) =>
                        total + parseInt(item.missionAmount as any),
                      0
                    )
                  )}
                </span>
              </Title>
              <Stack style={{ position: "absolute", right: 0, gap: 6 }}>
                <Text
                  fontSize={14}
                  lineHeight="14px"
                  fontWeight={500}
                  color={colors.primary}
                >
                  {t("show")}
                </Text>
                <Select
                  options={missionsListOptions.map((option) => option.label)}
                  selectedOption={missionsListSelectedOption}
                  onChange={(newValue) =>
                    setMissionsListSelectedOption(newValue)
                  }
                  popoverWidth={160}
                  style={{ width: 160 }}
                  loading={activitiesDataLoading}
                />
              </Stack>
            </Stack>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("missions"),
                      data: missionsList.map((item) => {
                        const dateToSet = new Date(item.missionDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.missionAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={activitiesDataLoading}
                  pointRadius={1.9}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
      <FadeFromTop delay={0.1} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("xp_overview")}</Title>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Title loading={activitiesDataLoading}>
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {formatNumber(
                    paymentsList.reduce(
                      (total, item) =>
                        total + parseInt(item.paymentAmount as any),
                      0
                    )
                  )}
                </span>
              </Title>
            </Stack>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("xp_points"),
                      data: paymentsList.map((item) => {
                        const dateToSet = new Date(item.paymentDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.paymentAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={activitiesDataLoading}
                  pointRadius={1.9}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
    </PageContainer>
  )
}

export default Activities
