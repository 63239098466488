import { createContext, ReactNode, useContext, useMemo } from "react"
import { MainContext } from "./main"
import { ActivitiesContext } from "./activities"
import { FootprintContext } from "./footprint"
import { EducationContext } from "./education"
import { OverviewContext } from "./overview"
import { ChallengesContext } from "./challenges"
import { UserContext } from "./user"

interface HelperContextInterface {
  loading: boolean
  getData: () => void
}

const HelperContext = createContext<HelperContextInterface>({
  loading: true,
  getData: () => {},
})

const HelperController = ({ children }: { children: ReactNode }) => {
  const { mainDataLoading, getMainData } = useContext(MainContext)
  const { overviewDataLoading, getOverviewData } = useContext(OverviewContext)
  const { activitiesDataLoading, getActivitiesData } =
    useContext(ActivitiesContext)
  const { footprintDataLoading, getFootprintData } =
    useContext(FootprintContext)
  const { educationDataLoading, getEducationData } =
    useContext(EducationContext)
  const { challengesDataLoading } = useContext(ChallengesContext)
  const { userDataLoading, getUserData } = useContext(UserContext)

  // states
  const loading = useMemo(
    () =>
      mainDataLoading ||
      activitiesDataLoading ||
      footprintDataLoading ||
      educationDataLoading ||
      overviewDataLoading ||
      challengesDataLoading ||
      userDataLoading,
    [
      mainDataLoading,
      activitiesDataLoading,
      footprintDataLoading,
      educationDataLoading,
      overviewDataLoading,
      challengesDataLoading,
      userDataLoading,
    ]
  )

  // functions
  const getData = () => {
    // get data from main controller
    getMainData()

    // get specific data only if user is in the correct route
    const pathname = window.location.pathname
    if (pathname.startsWith("/activities")) {
      getActivitiesData()
    } else if (pathname.startsWith("/footprint")) {
      getFootprintData()
    } else if (pathname.startsWith("/education")) {
      getEducationData()
    } else if (pathname.startsWith("/overview")) {
      getOverviewData()
    } else if (pathname.startsWith("/user")) {
      getUserData()
    }
  }

  return (
    <HelperContext.Provider
      value={{
        loading,
        getData,
      }}
    >
      {children}
    </HelperContext.Provider>
  )
}
export { HelperController, HelperContext }
