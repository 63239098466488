import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { colors } from "../../services/config/colors"
import LineChartSelectable from "../global/LineChartSelectable"
import { MainContext } from "../../controllers/main"
import OverviewCardDataItem from "../../models/overviewCardDataItem"
import { t } from "i18next"
import months from "../../services/config/months"
import { calculateDaysBetweenDates } from "../../services/utils/utils"
import { OverviewContext } from "../../controllers/overview"

const OverviewLineChart = ({
  selectedItem,
  interval,
  dataForChart,
  setDataForChart,
  dataVisibility,
  selectedOption,
  loading,
}: {
  selectedItem: number
  interval: {
    start: Date
    end: Date
  }
  dataForChart: OverviewCardDataItem[]
  setDataForChart: Dispatch<SetStateAction<OverviewCardDataItem[]>>
  dataVisibility: boolean[]
  selectedOption: number
  loading: boolean
}) => {
  const { dateRange } = useContext(MainContext)
  const { overviewCardData } = useContext(OverviewContext)

  // show chart after a delay to avoid lag
  const [showChart, setShowChart] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowChart(true)
    }, 350)
  }, [])

  // filtered data based on interval and dateRange
  useEffect(() => {
    const filteredData = overviewCardData.filter(
      (item) =>
        new Date(item.activityDate!) >=
          new Date(interval.start.toISOString().split("T")[0]) &&
        new Date(item.activityDate!) <=
          new Date(interval.end.toISOString().split("T")[0])
    )

    const startDate = new Date(interval.start)
    const allDates: Date[] = []
    do {
      allDates.push(new Date(startDate))
      startDate.setDate(startDate.getDate() + 1)
    } while (startDate <= interval.end)

    const dataToSet: any[] = []
    allDates.forEach((date) => {
      if (
        (dateRange.start &&
          new Date(date.toISOString().split("T")[0]) <
            new Date(dateRange.start.toISOString().split("T")[0])) ||
        (dateRange.end &&
          new Date(date.toISOString().split("T")[0]) >
            new Date(dateRange.end.toISOString().split("T")[0])) ||
        date > new Date() ||
        date.toDateString() === new Date().toDateString()
      ) {
        const dateToSet = new Date(date.toISOString().split("T")[0])
        dataToSet.push({
          activityAmount: null,
          activityDate: `${dateToSet.getDate()} ${t(
            months[dateToSet.getMonth()]
          )} ${dateToSet.getFullYear()}`,
          activityType: "episode",
          avgActivitiesDeltaPercentage: 0,
          avgActivityAmount: 0,
        })
        dataToSet.push({
          activityAmount: null,
          activityDate: `${dateToSet.getDate()} ${t(
            months[dateToSet.getMonth()]
          )} ${dateToSet.getFullYear()}`,
          activityType: "action",
          avgActivitiesDeltaPercentage: 0,
          avgActivityAmount: 0,
        })
      } else {
        for (let i = 0; i < 2; i++) {
          const typeToSearch = i === 0 ? "episode" : "action"
          const itemToPush = filteredData.find(
            (item) =>
              new Date(item.activityDate!).toDateString() ===
                date.toDateString() && item.activityType === typeToSearch
          )
          if (itemToPush) {
            const dateToSet = new Date(itemToPush.activityDate)
            dataToSet.push({
              ...itemToPush,
              activityDate: `${dateToSet.getDate()} ${t(
                months[dateToSet.getMonth()]
              )} ${dateToSet.getFullYear()}`,
            })
          } else {
            const dateToSet = new Date(date.toISOString().split("T")[0])
            dataToSet.push({
              activityAmount: 0,
              activityDate: `${dateToSet.getDate()} ${t(
                months[dateToSet.getMonth()]
              )} ${dateToSet.getFullYear()}`,
              activityType: typeToSearch,
              avgActivitiesDeltaPercentage: 0,
              avgActivityAmount: 0,
            })
          }
        }
      }
    })

    // aggregate data
    if (selectedOption === 3) {
      const monthsToAggregate: string[] = []
      dataToSet.forEach((item) => {
        const monthAndYear = item.activityDate.slice(
          item.activityDate.indexOf(" ") + 1
        )

        if (!monthsToAggregate.includes(monthAndYear)) {
          monthsToAggregate.push(monthAndYear)
        }
      })

      const aggregatedData = []

      for (let i = 0; i < monthsToAggregate.length; i++) {
        for (let i2 = 0; i2 < 2; i2++) {
          aggregatedData.push(
            dataToSet
              .filter(
                (item) =>
                  item.activityType === (i2 === 0 ? "episode" : "action") &&
                  item.activityDate.includes(t(monthsToAggregate[i]))
              )
              .reduce(
                (accumulator, item, index, array) => {
                  if (accumulator.activityAmount !== null) {
                    accumulator.activityAmount += item.activityAmount ?? 0
                  } else {
                    accumulator.activityAmount = item.activityAmount
                  }

                  accumulator.avgActivitiesDeltaPercentage +=
                    item.avgActivitiesDeltaPercentage

                  if (index === array.length - 1) {
                    accumulator.avgActivitiesDeltaPercentage /= index
                  }

                  return accumulator
                },
                {
                  activityAmount: null,
                  activityDate: monthsToAggregate[i],
                  activityType: i2 === 0 ? "episode" : "action",
                  avgActivitiesDeltaPercentage: 0,
                  avgActivityAmount: 0,
                }
              )
          )
        }
      }

      setDataForChart(
        aggregatedData.map((item) => {
          if (!dataVisibility.some((item) => item)) {
            item.activityAmount = null
          } else if (!dataVisibility[0] && item.activityType === "episode") {
            item.activityAmount = null
          } else if (!dataVisibility[1] && item.activityType === "action") {
            item.activityAmount = null
          }

          return item
        })
      )
    } else if (calculateDaysBetweenDates(interval.start, interval.end) >= 360) {
      const aggregatedData = []

      for (let i = 0; i < 12; i++) {
        for (let i2 = 0; i2 < 2; i2++) {
          aggregatedData.push(
            dataToSet
              .filter(
                (item) =>
                  item.activityType === (i2 === 0 ? "episode" : "action") &&
                  item.activityDate.includes(t(months[i]))
              )
              .reduce(
                (accumulator, item, index, array) => {
                  if (accumulator.activityAmount !== null) {
                    accumulator.activityAmount += item.activityAmount ?? 0
                  } else {
                    accumulator.activityAmount = item.activityAmount
                  }

                  accumulator.avgActivitiesDeltaPercentage +=
                    item.avgActivitiesDeltaPercentage

                  if (index === array.length - 1) {
                    accumulator.avgActivitiesDeltaPercentage /= index
                  }

                  return accumulator
                },
                {
                  activityAmount: null,
                  activityDate: `${t(
                    months[i]
                  )} ${interval.start.getFullYear()}`,
                  activityType: i2 === 0 ? "episode" : "action",
                  avgActivitiesDeltaPercentage: 0,
                  avgActivityAmount: 0,
                }
              )
          )
        }
      }

      setDataForChart(
        aggregatedData.map((item) => {
          if (!dataVisibility.some((item) => item)) {
            item.activityAmount = null
          } else if (!dataVisibility[0] && item.activityType === "episode") {
            item.activityAmount = null
          } else if (!dataVisibility[1] && item.activityType === "action") {
            item.activityAmount = null
          }

          return item
        })
      )
    } else {
      setDataForChart(
        dataToSet.map((item) => {
          if (!dataVisibility.some((item) => item)) {
            item.activityAmount = null
          } else if (!dataVisibility[0] && item.activityType === "episode") {
            item.activityAmount = null
          } else if (!dataVisibility[1] && item.activityType === "action") {
            item.activityAmount = null
          }

          return item
        })
      )
    }
  }, [overviewCardData, interval, dateRange, dataVisibility])

  return !showChart ? null : (
    <LineChartSelectable
      data={[
        {
          label: t("episodes"),
          data: dataForChart
            .filter((item) => item.activityType === "episode")
            .map((item) => {
              return {
                label: item.activityDate,
                value: item.activityAmount,
                avgActivitiesDeltaPercentage: item.avgActivitiesDeltaPercentage,
                backgroundColor: colors.categories.episodes,
              }
            }),
        },
        {
          label: t("actions"),
          data: dataForChart
            .filter((item) => item.activityType === "action")
            .map((item) => {
              return {
                label: item.activityDate,
                value: item.activityAmount,
                avgActivitiesDeltaPercentage: item.avgActivitiesDeltaPercentage,
                backgroundColor: colors.categories.actions,
              }
            }),
        },
      ]}
      selectedDataset={selectedItem}
      loading={loading}
    />
  )
}

export default OverviewLineChart
