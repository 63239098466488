import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import awLogoIcon from "../../assets/images/aw-logo-icon.svg"
import Title from "../global/Title"
import MenuItem from "./MenuItem"
import { accountRoutes, contentRoutes } from "../../services/config/routes"
import { t } from "i18next"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import MenuItemButton from "./MenuItemButton"
import { AuthContext } from "../../controllers/auth"
import TeamSelect from "../team/TeamSelect"
import Team from "../../models/team"
import { useNavigate } from "react-router-dom"

const Menu = () => {
  const navigate = useNavigate()
  const {
    setLogOutAlertOpen,
    team,
    setTeam,
    teamsList,
    isAdmin,
    isSuperAdmin,
  } = useContext(AuthContext)
  const {
    getMainDataFirstTime,
    selectedFilters,
    setSelectedFilters,
    dateRange,
    setDateRange,
  } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: 298,
        minWidth: 298,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        overflowY: "scroll",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 24,
        paddingBottom: 24,
        paddingInline: 16,
      }}
    >
      <Stack direction="row" gap={2} style={{ width: "100%" }}>
        <img src={awLogoIcon} alt="AWorld" style={{ height: 38 }} />
        <Title
          fontSize={16}
          lineHeight="20px"
          fontWeight={900}
          color={colors.primaryLogo}
          style={{ textTransform: "uppercase" }}
        >
          {t("impact_management_platform")}
        </Title>
      </Stack>
      <Stack gap={1}>
        <Title
          fontSize={26}
          lineHeight="33px"
          fontWeight={900}
          style={{ marginTop: 36 }}
          skeletonWidth={150}
        >
          {team?.title}
        </Title>
        {teamsList.length > 1 ? (
          <TeamSelect
            options={teamsList}
            selectedOption={team}
            onChange={(newTeam: Team) => {
              setTeam(newTeam)

              // reset filters
              selectedFilters.splice(0, selectedFilters.length)
              setSelectedFilters([...selectedFilters])
              if (isSuperAdmin) {
                dateRange.start = null
                dateRange.end = null
              } else {
                dateRange.start = isAdmin
                  ? new Date("2023-04-20")
                  : new Date("2024-09-01")
                dateRange.end = new Date()
              }
              setDateRange({ ...dateRange })

              // redirects
              const pathname = window.location.pathname

              if (
                pathname.startsWith("/overview/leaderboard") ||
                pathname.startsWith("/overview/groups-leaderboard")
              ) {
                navigate("/overview")
              } else if (pathname.startsWith("/community-missions/")) {
                navigate("/community-missions")
              }

              getMainDataFirstTime(newTeam.id)
            }}
          />
        ) : null}
      </Stack>
      <Stack style={{ width: "100%", marginTop: 36 }} gap={2}>
        {contentRoutes.map((route, index) => (
          <MenuItem key={index} route={route} />
        ))}
        <Title
          fontSize={16}
          fontWeight={900}
          style={{
            marginTop: 16,
            letterSpacing: "1px",
            textTransform: "uppercase",
          }}
        >
          {t("account_pages")}
        </Title>
        {accountRoutes.map((route, index) => (
          <MenuItem key={index} route={route} />
        ))}
        <MenuItemButton
          title={t("logout")}
          onClick={() => {
            setLogOutAlertOpen(true)
          }}
        />
      </Stack>
    </Stack>
  )
}

export default Menu
