import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react"
import {
  dashboardActivityList,
  dashboardMissionList,
  dashboardPaymentList,
} from "../services/graphql/queries"
import ActivitiesListItem from "../models/activitiesListItem"
import { enumAsArray, logger, Status } from "../services/utils/utils"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import { t } from "i18next"
import {
  DashboardActivityType,
  DashboardMissionType,
} from "../services/config/enum"
import SelectOption from "../models/selectOption"
import MissionsListItem from "../models/missionsListItem"
import PaymentsListItem from "../models/paymentsListItem"

interface ActivitiesContextInterface {
  activitiesDataLoading: boolean
  activitiesList: ActivitiesListItem[]
  activitiesListOptions: SelectOption[]
  activitiesListSelectedOption: number
  setActivitiesListSelectedOption: Dispatch<SetStateAction<number>>
  missionsList: MissionsListItem[]
  missionsListOptions: SelectOption[]
  missionsListSelectedOption: number
  setMissionsListSelectedOption: Dispatch<SetStateAction<number>>
  paymentsList: PaymentsListItem[]
  getActivitiesData: () => void
}

const ActivitiesContext = createContext<ActivitiesContextInterface>({
  activitiesDataLoading: true,
  activitiesList: [],
  activitiesListOptions: [],
  activitiesListSelectedOption: 0,
  setActivitiesListSelectedOption: () => {},
  missionsList: [],
  missionsListOptions: [],
  missionsListSelectedOption: 0,
  setMissionsListSelectedOption: () => {},
  paymentsList: [],
  getActivitiesData: () => {},
})

const ActivitiesController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { getInput, setError, setErrorMessage } = useContext(MainContext)

  // states
  const [activitiesDataLoading, setActivitiesDataLoading] =
    useState<boolean>(false)
  const [activitiesList, setActivitiesList] = useState<ActivitiesListItem[]>([])
  const activitiesListOptions: SelectOption[] = useMemo(
    () => [
      { id: "all", label: t("all") },
      ...enumAsArray(DashboardActivityType).map((key) => {
        return {
          id: key as string,
          label:
            key === "episode"
              ? t("episodes")
              : key === "action"
              ? t("routines")
              : key === "survey"
              ? t("surveys")
              : t(key as string),
        }
      }),
    ],
    []
  )
  const [activitiesListSelectedOption, setActivitiesListSelectedOption] =
    useState<number>(0)
  const [missionsList, setMissionsList] = useState<MissionsListItem[]>([])
  const missionsListOptions: SelectOption[] = useMemo(
    () => [
      { id: "all", label: t("all") },
      ...enumAsArray(DashboardMissionType).map((key) => {
        return {
          id: key as string,
          label:
            key === "episode"
              ? t("episodes")
              : key === "action"
              ? t("routines")
              : key === "streak"
              ? t("streak")
              : key === "activity"
              ? t("activities")
              : key === "exp"
              ? t("xp")
              : t(key as string),
        }
      }),
    ],
    []
  )
  const [missionsListSelectedOption, setMissionsListSelectedOption] =
    useState<number>(0)
  const [paymentsList, setPaymentsList] = useState<PaymentsListItem[]>([])

  // queries
  const [dashboardActivityListQuery] = useLazyQuery(dashboardActivityList)
  const [dashboardMissionListQuery] = useLazyQuery(dashboardMissionList)
  const [dashboardPaymentListQuery] = useLazyQuery(dashboardPaymentList)

  // get activities list
  const getActivitiesList = async () => {
    try {
      logger(Status.Api, "[ACT] QUERY activityListV2")

      const input = getInput(team!.id)

      const { data } = await dashboardActivityListQuery({
        variables: {
          input: {
            ...input,
            limit: 1000,
            ...(!activitiesListSelectedOption
              ? {}
              : {
                  activityType:
                    activitiesListOptions[activitiesListSelectedOption].id,
                }),
          },
        },
      })

      logger(
        Status.Info,
        "[ACT] activities list",
        data.dashboardActivityListV2.items
      )

      setActivitiesList(data.dashboardActivityListV2.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Activities`)
      setActivitiesList([])

      return false
    }
  }

  // get missions list
  const getMissionsList = async () => {
    try {
      logger(Status.Api, "[ACT] QUERY missionListV2")

      const input = getInput(team!.id)

      const { data } = await dashboardMissionListQuery({
        variables: {
          input: {
            ...input,
            limit: 1000,
            ...(!missionsListSelectedOption
              ? {}
              : {
                  missionType:
                    missionsListOptions[missionsListSelectedOption].id,
                }),
          },
        },
      })

      logger(
        Status.Info,
        "[ACT] missions list",
        data.dashboardMissionListV2.items
      )

      setMissionsList(data.dashboardMissionListV2.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Missions`)
      setMissionsList([])

      return false
    }
  }

  // get payments list
  const getPaymentsList = async () => {
    try {
      logger(Status.Api, "[ACT] QUERY paymentListV2")

      const input = getInput(team!.id)

      const { data } = await dashboardPaymentListQuery({
        variables: {
          input: {
            ...input,
            limit: 1000,
          },
        },
      })

      logger(
        Status.Info,
        "[ACT] payments list",
        data.dashboardPaymentListV2.items
      )

      setPaymentsList(data.dashboardPaymentListV2.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Payments`)
      setPaymentsList([])

      return false
    }
  }

  // get all data
  const getActivitiesData = async () => {
    setActivitiesDataLoading(true)

    await Promise.all([
      getActivitiesList(),
      getMissionsList(),
      getPaymentsList(),
    ])

    setActivitiesDataLoading(false)
  }

  return (
    <ActivitiesContext.Provider
      value={{
        activitiesDataLoading,
        activitiesList,
        activitiesListOptions,
        activitiesListSelectedOption,
        setActivitiesListSelectedOption,
        missionsList,
        missionsListOptions,
        missionsListSelectedOption,
        setMissionsListSelectedOption,
        paymentsList,
        getActivitiesData,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  )
}
export { ActivitiesController, ActivitiesContext }
