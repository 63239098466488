import { createContext, ReactNode, useContext, useState } from "react"
import { MainContext } from "./main"
import { AuthContext } from "./auth"
import { t } from "i18next"
import { logger, Status } from "../services/utils/utils"
import { useLazyQuery } from "@apollo/client"
import { dashboardUserGet } from "../services/graphql/queries"
import UsersByTimeListItem from "../models/usersByTimeListItem"

interface UserContextInterface {
  userDataLoading: boolean
  usersByTimeList: UsersByTimeListItem[]
  getUserData: () => void
}

const UserContext = createContext<UserContextInterface>({
  userDataLoading: true,
  usersByTimeList: [],
  getUserData: () => {},
})

const UserController = ({ children }: { children: ReactNode }) => {
  const { team } = useContext(AuthContext)
  const { getInput, setError, setErrorMessage } = useContext(MainContext)

  // states
  const [userDataLoading, setActivitiesDataLoading] = useState<boolean>(false)
  const [usersByTimeList, setUsersByTimeList] = useState<UsersByTimeListItem[]>(
    []
  )

  // queries
  const [dashboardUserGetQuery] = useLazyQuery(dashboardUserGet)

  // get users by time list
  const getUsersByTimeList = async () => {
    try {
      logger(Status.Api, "[ACT] QUERY userGet")

      const input: any = getInput(team!.id)
      delete input.lang

      const { data } = await dashboardUserGetQuery({
        variables: {
          input: {
            ...input,
            limit: 1000,
          },
        },
      })

      logger(
        Status.Info,
        "[USER] users by time list",
        data.dashboardUserGet.items
      )

      setUsersByTimeList(data.dashboardUserGet.items)

      return true
    } catch (e) {
      console.log(e)

      setError(true)
      setErrorMessage(`${t("error")} Users By Time`)
      setUsersByTimeList([])

      return false
    }
  }

  // get all data
  const getUserData = async () => {
    setActivitiesDataLoading(true)

    await Promise.all([getUsersByTimeList()])

    setActivitiesDataLoading(false)
  }

  return (
    <UserContext.Provider
      value={{
        userDataLoading,
        usersByTimeList,
        getUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
export { UserController, UserContext }
