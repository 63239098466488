import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Pie } from "react-chartjs-2"
import { colors } from "../../services/config/colors"
import ChartDataItem from "../../models/chartDataItem"
import { Skeleton } from "@mui/material"

ChartJS.register(ArcElement, Tooltip, ChartDataLabels)

const PieChart = ({
  data,
  loading,
  skeletonDimension,
}: {
  data: ChartDataItem[]
  loading: boolean
  skeletonDimension?: number
}) => {
  // data for chart
  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        label: "Dataset 1",
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.backgroundColor),
        hoverBackgroundColor: data.map((item) => item.backgroundColor),
        colors: data.some((item) => !item.color)
          ? data.map((item) => colors.text)
          : data.map((item) => item.color),
      },
    ],
  }

  return loading ? (
    <div
      style={{
        width: skeletonDimension,
        height: skeletonDimension,
        padding: 76,
      }}
    >
      <Skeleton variant="circular" style={{ width: "100%", height: "100%" }} />
    </div>
  ) : (
    <Pie
      style={{ maxWidth: "100%" }}
      options={{
        cutout: "68%",
        events: [],
        layout: {
          padding: {
            left: 76,
            right: 76,
            top: 76,
            bottom: 76,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
            borderRadius: 1000,
            spacing: 6,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: "auto",
            color: (context: any) => {
              return context.chart.data.datasets[0].colors[context.dataIndex]
            },
            formatter: (value: any, context: any) => {
              return context.chart.data.labels[context.dataIndex]
            },
            anchor: "end",
            align: "center",
            textAlign: "center",
            font: {
              weight: 700,
              size: 12,
              family: "Satoshi",
            },
            backgroundColor: (context: any) => {
              return context.chart.data.datasets[0].backgroundColor[
                context.dataIndex
              ]
            },
            borderColor: "white",
            borderWidth: 2,
            borderRadius: 4,
            padding: {
              left: 8,
              right: 8,
              top: 3,
              bottom: 3,
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      data={chartData}
    />
  )
}

export default PieChart
