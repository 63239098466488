import { useContext } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { AuthContext } from "../../../controllers/auth"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { colors } from "../../../services/config/colors"

const MobilityManagement = () => {
  const { team } = useContext(AuthContext)

  return (
    <PageContainer
      title={t("mobility_management")}
      breadcrumbs={[{ title: t("mobility_management") }]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
    >
      {!team ? null : (
        <FadeFromTop
          style={{
            height: "74vh",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            src={
              "https://lookerstudio.google.com/embed/reporting/76af5080-c7d7-40b7-9e95-6f7ca1493f2c/page/JAodE?params=" +
              encodeURI(
                JSON.stringify({
                  "ds1.bridge_team_id": team.id,
                  "ds2.bridge_team_id": team.id,
                })
              )
            }
          />
        </FadeFromTop>
      )}
    </PageContainer>
  )
}

export default MobilityManagement
