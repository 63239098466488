import { useContext } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { AuthContext } from "../../../controllers/auth"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { colors } from "../../../services/config/colors"

const MobilityAssistant = () => {
  const { team } = useContext(AuthContext)

  return (
    <PageContainer
      title={t("mobility_assistant")}
      breadcrumbs={[{ title: t("mobility_assistant") }]}
      withFilters={false}
      withDatePicker={false}
      withExportButton={false}
      infoText={t("mobility_assistant_info")}
    >
      {!team ? null : (
        <FadeFromTop
          style={{
            height: "74vh",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            src={
              "https://lookerstudio.google.com/embed/reporting/06c616df-4472-4120-9871-abcdd920ae1d/page/F952D?params=" +
              encodeURI(
                JSON.stringify({
                  "ds14.teamid": team.id,
                  "ds15.teamid": team.id,
                })
              )
            }
          />
        </FadeFromTop>
      )}
    </PageContainer>
  )
}

export default MobilityAssistant
