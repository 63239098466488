import { useContext, useEffect } from "react"
import PageContainer from "../../../components/global/PageContainer"
import { t } from "i18next"
import { AuthContext } from "../../../controllers/auth"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import { colors } from "../../../services/config/colors"
import { UserContext } from "../../../controllers/user"
import Card from "../../../components/global/Card"
import { Stack } from "@mui/material"
import Title from "../../../components/global/Title"
import LineChart from "../../../components/global/LineChart"
import months from "../../../services/config/months"
import { formatNumber } from "../../../services/utils/utils"

const User = () => {
  const { team } = useContext(AuthContext)
  const { userDataLoading, getUserData, usersByTimeList } =
    useContext(UserContext)

  // get data
  useEffect(() => {
    getUserData()
  }, [team])

  return (
    <PageContainer
      title={t("user")}
      breadcrumbs={[{ title: t("user") }]}
      infoText={t("user_info")}
    >
      <FadeFromTop>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("new_users")}</Title>
            <Stack
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Title loading={userDataLoading}>
                {t("total")}:{" "}
                <span style={{ color: colors.primary }}>
                  {formatNumber(
                    usersByTimeList.reduce(
                      (total, item) => total + item.userNew,
                      0
                    )
                  )}
                </span>
              </Title>
            </Stack>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("new_users"),
                      data: usersByTimeList.map((item) => {
                        const dateToSet = new Date(item.userDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.userNew,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={userDataLoading}
                  pointRadius={1.2}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
      <FadeFromTop delay={0.05} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("total_users")}</Title>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("users"),
                      data: usersByTimeList.map((item) => {
                        const dateToSet = new Date(item.userDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.userAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={userDataLoading}
                  pointRadius={1.2}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
      <FadeFromTop delay={0.1} style={{ marginTop: 24 }}>
        <Card style={{ overflowX: "scroll" }}>
          <Stack gap={3} style={{ minWidth: 880 }}>
            <Title>{t("active_users")}</Title>
            <Stack>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("users"),
                      data: usersByTimeList.map((item) => {
                        const dateToSet = new Date(item.userDate)

                        return {
                          label: `${dateToSet.getDate()} ${t(
                            months[dateToSet.getMonth()]
                          )} ${dateToSet.getFullYear()}`,
                          value: item.userActiveAmount,
                          backgroundColor: colors.categories.episodes,
                        }
                      }),
                    },
                  ]}
                  loading={userDataLoading}
                  pointRadius={1.2}
                />
              </div>
            </Stack>
          </Stack>
        </Card>
      </FadeFromTop>
    </PageContainer>
  )
}

export default User
