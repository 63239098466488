import { t } from "i18next"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import Table from "../../../components/global/Table"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { colors } from "../../../services/config/colors"
import TableRow from "../../../components/global/TableRow"
import { Stack, TableCell } from "@mui/material"
import Text from "../../../components/global/Text"
import { MainContext } from "../../../controllers/main"
import CompactFilters from "../../../components/global/CompactFilters"
import { formatNumber } from "../../../services/utils/utils"
import { OverviewContext } from "../../../controllers/overview"
import { AuthContext } from "../../../controllers/auth"

const GroupsLeaderboard = () => {
  const { team } = useContext(AuthContext)
  const { groupsList } = useContext(MainContext)
  const {
    overviewDataLoading,
    getOverviewData,
    groupsLeaderboard,
    groupsLeaderboardNextToken,
    loadMoreGroupsLeaderboardItems,
  } = useContext(OverviewContext)

  // get data
  useEffect(() => {
    getOverviewData()
  }, [team])

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {t("groups")}{" "}
        <span style={{ color: colors.textSecondary }}>
          ({groupsLeaderboard.length})
        </span>
      </>,
      t("total_points"),
    ]
  }, [groupsLeaderboard])

  // page container onScroll event to fetch new items on scroll
  const fetchingMoreItems = useRef<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const onScroll = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      groupsLeaderboardNextToken &&
      !overviewDataLoading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      await loadMoreGroupsLeaderboardItems()

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  return (
    <PageContainer
      title={t("groups_leaderboard")}
      breadcrumbs={[
        { title: t("menu_overview"), path: "/overview" },
        { title: t("groups_leaderboard") },
      ]}
      withFilters={false}
      paddingTop={24}
      variant="compact"
      onScroll={onScroll}
      csvData={[
        [t("position"), t("group"), t("total_points")],
        ...groupsLeaderboard.map((item, index) => [
          (index + 1).toString(),
          groupsList.find((group) => group.id === item.groupId)?.name ?? "",
          formatNumber(item.groupPoints),
        ]),
      ]}
      csvFileName="groups-leaderboard"
    >
      <div
        style={{ width: "100%", height: 1, backgroundColor: colors.stroke }}
      />
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        <Table
          headingItems={headingItems}
          loading={overviewDataLoading}
          loadingMore={loadingMore}
        >
          {groupsLeaderboard.map((item, index) => (
            <TableRow index={index} key={index}>
              <TableCell>
                <Stack direction="row" alignItems="center" style={{ gap: 20 }}>
                  <Text fontSize={14} fontWeight={500}>
                    {index + 1}°
                  </Text>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundImage:
                        "url(https://cdn.aworld.io/users/default/profile.jpg)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Text fontSize={14} fontWeight={500}>
                    {groupsList.some((group) => group.id === item.groupId)
                      ? groupsList.find((group) => group.id === item.groupId)
                          ?.name
                      : "null"}
                  </Text>
                </Stack>
              </TableCell>
              <TableCell>
                <Text fontSize={14} fontWeight={500}>
                  {formatNumber(item.groupPoints)}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default GroupsLeaderboard
