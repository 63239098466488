import { Skeleton, Stack } from "@mui/material"
import { CSSProperties, useContext } from "react"
import Text from "../global/Text"
import { colors } from "../../services/config/colors"
import PointsBadge from "../global/PointsBadge"
import { OverviewContext } from "../../controllers/overview"
import { MainContext } from "../../controllers/main"

const ChallengeGroupsLeaderboardTop3 = ({
  loading,
  style,
}: {
  loading: boolean
  style?: CSSProperties
}) => {
  const { groupsList } = useContext(MainContext)
  const { groupsLeaderboard } = useContext(OverviewContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: 148,
        paddingInline: 16,
        ...style,
      }}
    >
      <Stack direction="row-reverse" style={{ width: "calc(100% / 3 * 2)" }}>
        {/* first position */}
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label="Place 1 on the leaderboard"
        >
          {loading ? (
            <Skeleton variant="circular" style={{ width: 81, height: 81 }} />
          ) : (
            <div
              style={{
                width: 81,
                height: 81,
                borderRadius: "100%",
                backgroundImage:
                  "url(https://cdn.aworld.io/users/default/profile.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: "100%",
                  backgroundColor: colors.primary,
                  position: "absolute",
                  top: 4,
                  left: -6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text fontSize={14} fontWeight={700} color={colors.textWhite}>
                  1
                </Text>
              </div>
            </div>
          )}
          <Text
            fontSize={14}
            lineHeight="19px"
            fontWeight={700}
            style={{
              height: 19,
              marginTop: 2,
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            loading={loading}
            skeletonWidth={70}
          >
            {groupsLeaderboard[0]
              ? groupsList.some(
                  (group) => group.id === groupsLeaderboard[0].groupId
                )
                ? groupsList.find(
                    (group) => group.id === groupsLeaderboard[0].groupId
                  )?.name
                : "null"
              : ""}
          </Text>
          {groupsLeaderboard[0] ? (
            <PointsBadge
              points={groupsLeaderboard[0].groupPoints}
              loading={loading}
              style={{ marginTop: 4.5 }}
            />
          ) : (
            <div
              style={{
                width: "auto",
                height: 22,
                marginTop: 4.5,
              }}
            />
          )}
        </Stack>
        {/* second position */}
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label="Place 2 on the leaderboard"
        >
          {loading ? (
            <Skeleton variant="circular" style={{ width: 63, height: 63 }} />
          ) : (
            <div
              style={{
                width: 63,
                height: 63,
                borderRadius: "100%",
                backgroundImage:
                  "url(https://cdn.aworld.io/users/default/profile.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "100%",
                  backgroundColor: colors.primary,
                  position: "absolute",
                  top: 4,
                  left: -6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text fontSize={14} fontWeight={700} color={colors.textWhite}>
                  2
                </Text>
              </div>
            </div>
          )}
          <Text
            fontSize={14}
            lineHeight="19px"
            fontWeight={700}
            style={{
              height: 19,
              marginTop: 2,
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            loading={loading}
            skeletonWidth={70}
          >
            {groupsLeaderboard[1]
              ? groupsList.some(
                  (group) => group.id === groupsLeaderboard[1].groupId
                )
                ? groupsList.find(
                    (group) => group.id === groupsLeaderboard[1].groupId
                  )?.name
                : "null"
              : ""}
          </Text>
          {groupsLeaderboard[1] ? (
            <PointsBadge
              points={groupsLeaderboard[1].groupPoints}
              loading={loading}
              style={{ marginTop: 4.5 }}
            />
          ) : (
            <div
              style={{
                width: "auto",
                height: 22,
                marginTop: 5,
              }}
            />
          )}
        </Stack>
      </Stack>
      {/* third position */}
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        style={{ width: "calc(100% / 3)", height: "100%" }}
        aria-label="Place 3 on the leaderboard"
      >
        {loading ? (
          <Skeleton variant="circular" style={{ width: 63, height: 63 }} />
        ) : (
          <div
            style={{
              width: 63,
              height: 63,
              borderRadius: "100%",
              backgroundImage:
                "url(https://cdn.aworld.io/users/default/profile.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: "100%",
                backgroundColor: colors.primary,
                position: "absolute",
                top: 4,
                left: -6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                fontSize={14}
                fontWeight={700}
                color={colors.textWhite}
                ariaHidden
              >
                3
              </Text>
            </div>
          </div>
        )}
        <Text
          fontSize={14}
          lineHeight="19px"
          fontWeight={700}
          style={{
            height: 19,
            marginTop: 2,
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          loading={loading}
          skeletonWidth={70}
        >
          {groupsLeaderboard[2]
            ? groupsList.some(
                (group) => group.id === groupsLeaderboard[2].groupId
              )
              ? groupsList.find(
                  (group) => group.id === groupsLeaderboard[2].groupId
                )?.name
              : "null"
            : ""}
        </Text>
        {groupsLeaderboard[2] ? (
          <PointsBadge
            points={groupsLeaderboard[2].groupPoints}
            loading={loading}
            style={{ marginTop: 4.5 }}
          />
        ) : (
          <div
            style={{
              width: "auto",
              height: 22,
              marginTop: 5,
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default ChallengeGroupsLeaderboardTop3
