import { useContext } from "react"
import Loading from "./views/public/loading"
import LogIn from "./views/public/logIn"
import Main from "./views/private/main"
import { AuthContext } from "./controllers/auth"
import Center from "./components/global/Center"
import Text from "./components/global/Text"
import Button from "./components/global/Button"
import { MainController } from "./controllers/main"
import { ActivitiesController } from "./controllers/activities"
import { HelperController } from "./controllers/helper"
import { FootprintController } from "./controllers/footprint"
import { EducationController } from "./controllers/education"
import { Compose } from "./services/utils/utils"
import { OverviewController } from "./controllers/overview"
import { ChallengesController } from "./controllers/challenges"
import { UserController } from "./controllers/user"

const App = () => {
  const { loading, isAuth, viewNoTeamPage, logOut } = useContext(AuthContext)

  // all controllers
  const controllers = [
    MainController,
    OverviewController,
    EducationController,
    FootprintController,
    ActivitiesController,
    ChallengesController,
    UserController,
    HelperController,
  ]

  if (loading) {
    return <Loading />
  }

  if (!isAuth) {
    return <LogIn />
  }

  if (viewNoTeamPage) {
    return (
      <Center>
        <Text>No teams</Text>
        <Button onClick={logOut} style={{ width: 100, marginTop: 20 }}>
          Logout
        </Button>
      </Center>
    )
  }

  return (
    <Compose components={controllers}>
      <Main />
    </Compose>
  )
}

export default App
