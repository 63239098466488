import Route from "../../models/route"
import Overview from "../../views/private/overview/overview"
import Footprint from "../../views/private/footprint/footprint"
import Education from "../../views/private/education/education"
import Library from "../../views/private/library/library"
import overviewPrimaryIcon from "../../assets/icons/overview-primary.svg"
import overviewWhiteIcon from "../../assets/icons/overview-white.svg"
import challengesPrimaryIcon from "../../assets/icons/challenges-primary.svg"
import challengesWhiteIcon from "../../assets/icons/challenges-white.svg"
import activitiesPrimaryIcon from "../../assets/icons/activities-primary.svg"
import activitiesWhiteIcon from "../../assets/icons/activities-white.svg"
import mobilityPrimaryIcon from "../../assets/icons/mobility-primary.svg"
import mobilityWhiteIcon from "../../assets/icons/mobility-white.svg"
import mobilityManagementPrimaryIcon from "../../assets/icons/mobility-management-primary.svg"
import mobilityManagementWhiteIcon from "../../assets/icons/mobility-management-white.svg"
import userPrimaryIcon from "../../assets/icons/user-primary.svg"
import userWhiteIcon from "../../assets/icons/user-white.svg"
import co2PrimaryIcon from "../../assets/icons/co2-primary.svg"
import co2WhiteIcon from "../../assets/icons/co2-white.svg"
import educationPrimaryIcon from "../../assets/icons/education-primary.svg"
import educationWhiteIcon from "../../assets/icons/education-white.svg"
import communicationPrimaryIcon from "../../assets/icons/communication-primary.svg"
import communicationWhiteIcon from "../../assets/icons/communication-white.svg"
// import profilePrimaryIcon from "../../assets/icons/profile-primary.svg"
// import profileWhiteIcon from "../../assets/icons/profile-white.svg"
// import Profile from "../../views/private/profile"
import Leaderboard from "../../views/private/overview/leaderboard"
import AllSdgs from "../../views/private/education/allSdgs"
import AllCategories from "../../views/private/education/allCategories"
import AllContent from "../../views/private/education/allContent"
import MailFlowMotivational from "../../views/private/library/mailFlowMotivational"
import MailFlowStorytelling from "../../views/private/library/mailFlowStorytelling"
import Users from "../../views/private/overview/users"
import Activities from "../../views/private/activities/activities"
import MobilityAssistant from "../../views/private/mobilityAssistant/mobilityAssistant"
import EducationHours from "../../views/private/overview/educationHours"
import User from "../../views/private/user/user"
import MobilityManagement from "../../views/private/mobilityManagement/mobilityManagement"
import Challenges from "../../views/private/challenges/challenges"
import PastChallengeLeaderboard from "../../views/private/challenges/pastChallengeLeaderboard"
import GroupsLeaderboard from "../../views/private/overview/groupsLeaderboard"
import PastChallengeGroupsLeaderboard from "../../views/private/challenges/pastChallengeGroupsLeaderboard"

export const contentRoutes: Route[] = [
  {
    name: "menu_overview",
    path: "/overview",
    component: <Overview />,
    iconSelected: overviewWhiteIcon,
    iconDeselected: overviewPrimaryIcon,
  },
  {
    name: "challenges",
    path: "/community-missions",
    component: <Challenges />,
    iconSelected: challengesWhiteIcon,
    iconDeselected: challengesPrimaryIcon,
  },
  {
    name: "activities_and_missions",
    path: "/activities",
    component: <Activities />,
    iconSelected: activitiesWhiteIcon,
    iconDeselected: activitiesPrimaryIcon,
  },
  {
    name: "user",
    path: "/user",
    component: <User />,
    iconSelected: userWhiteIcon,
    iconDeselected: userPrimaryIcon,
  },
  {
    name: "carbon_footprint",
    path: "/footprint",
    component: <Footprint />,
    iconSelected: co2WhiteIcon,
    iconDeselected: co2PrimaryIcon,
  },
  {
    name: "education",
    path: "/education",
    component: <Education />,
    iconSelected: educationWhiteIcon,
    iconDeselected: educationPrimaryIcon,
  },
  {
    name: "mobility_assistant",
    path: "/mobility-assistant",
    component: <MobilityAssistant />,
    iconSelected: mobilityWhiteIcon,
    iconDeselected: mobilityPrimaryIcon,
  },
  {
    name: "mobility_management",
    path: "/mobility-management",
    component: <MobilityManagement />,
    iconSelected: mobilityManagementWhiteIcon,
    iconDeselected: mobilityManagementPrimaryIcon,
  },
  {
    name: "operational_kit",
    path: "/library",
    component: <Library />,
    iconSelected: communicationWhiteIcon,
    iconDeselected: communicationPrimaryIcon,
  },
]

export const accountRoutes: Route[] = [
  // {
  //   name: "profile",
  //   path: "/profile",
  //   component: <Profile />,
  //   iconSelected: profileWhiteIcon,
  //   iconDeselected: profilePrimaryIcon,
  // },
]

export const otherRoutes: Route[] = [
  {
    name: "leaderboard",
    path: "/overview/leaderboard",
    component: <Leaderboard />,
  },
  {
    name: "groups_leaderboard",
    path: "/overview/groups-leaderboard",
    component: <GroupsLeaderboard />,
  },
  {
    name: "education_hours",
    path: "/overview/education-hours",
    component: <EducationHours />,
  },
  {
    name: "total_users",
    path: "/overview/users",
    component: <Users />,
  },
  {
    name: "leaderboard",
    path: "/community-missions/leaderboard/:challengeId",
    component: <PastChallengeLeaderboard />,
  },
  {
    name: "leaderboard",
    path: "/community-missions/groups-leaderboard/:challengeId",
    component: <PastChallengeGroupsLeaderboard />,
  },
  {
    name: "all_sdgs",
    path: "/education/sdgs",
    component: <AllSdgs />,
  },
  {
    name: "all_categories",
    path: "/education/categories",
    component: <AllCategories />,
  },
  {
    name: "all_content",
    path: "/education/content",
    component: <AllContent />,
  },
  {
    name: "mail_flow_motivational",
    path: "/library/motivational",
    component: <MailFlowMotivational />,
  },
  {
    name: "mail_flow_storytelling",
    path: "/library/storytelling",
    component: <MailFlowStorytelling />,
  },
]
