import { t } from "i18next"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import PageContainer from "../../../components/global/PageContainer"
import Table from "../../../components/global/Table"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { colors } from "../../../services/config/colors"
import TableRow from "../../../components/global/TableRow"
import { Stack, TableCell } from "@mui/material"
import Text from "../../../components/global/Text"
import CompactFilters from "../../../components/global/CompactFilters"
import { convertNumberToHoursMinutes } from "../../../services/utils/utils"
import { AuthContext } from "../../../controllers/auth"
import { TeamStakeholderType } from "../../../services/config/enum"
import { OverviewContext } from "../../../controllers/overview"

const EducationHours = () => {
  const { team } = useContext(AuthContext)
  const {
    overviewDataLoading,
    getOverviewData,
    educationHoursUsersList,
    educationHoursUsersListNextToken,
    loadMoreEducationHoursUsersListItems,
  } = useContext(OverviewContext)

  // get data
  useEffect(() => {
    getOverviewData()
  }, [team])

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {t("users")}{" "}
        <span style={{ color: colors.textSecondary }}>
          ({educationHoursUsersList.length})
        </span>
      </>,
      t("total_education_hours"),
    ]
  }, [educationHoursUsersList])

  // page container onScroll event to fetch new items on scroll
  const fetchingMoreItems = useRef<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const onScroll = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      educationHoursUsersListNextToken &&
      !overviewDataLoading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      await loadMoreEducationHoursUsersListItems()

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  return (
    <PageContainer
      title={t("education_hours")}
      breadcrumbs={[
        { title: t("menu_overview"), path: "/overview" },
        { title: t("education_hours") },
      ]}
      withFilters={false}
      paddingTop={24}
      variant="compact"
      onScroll={onScroll}
    >
      <div
        style={{ width: "100%", height: 1, backgroundColor: colors.stroke }}
      />
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        <Table
          headingItems={headingItems}
          loading={overviewDataLoading}
          loadingMore={loadingMore}
        >
          {educationHoursUsersList.map((item, index) => (
            <TableRow index={index} key={index}>
              <TableCell>
                <Stack direction="row" alignItems="center" style={{ gap: 20 }}>
                  <Text fontSize={14} fontWeight={500}>
                    {index + 1}°
                  </Text>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundImage: `url(${item.user.profileImage}), url(http://cdn.aworld.io/users/${item.user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Text fontSize={14} fontWeight={500}>
                    {team?.stakeholderType === TeamStakeholderType.employees
                      ? `${item.user.first_name} ${item.user.last_name}`
                      : item.user.nickname ?? item.user.uid}
                  </Text>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Text fontSize={14} fontWeight={500}>
                    {convertNumberToHoursMinutes(item.totalEducationHours)}
                  </Text>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default EducationHours
