import FootprintBreakdownByCategoriesDataItem from "../../models/footprintBreakdownByCategoriesDataItem"
import { deepCopy, roundWithDecimalPlaces } from "./utils"

export const parseFootprintByCategoryData = (data: any) => {
  const dataToSet: FootprintBreakdownByCategoriesDataItem[] = deepCopy(data)
  dataToSet.forEach((item) => {
    item.categoryEmissionAmountAvg = roundWithDecimalPlaces(
      item.categoryEmissionAmountAvg / 1000,
      2
    )
    item.sectorEmissionAmountAvg = roundWithDecimalPlaces(
      item.sectorEmissionAmountAvg / 1000,
      2
    )
    item.sectorEmissionPercentage = roundWithDecimalPlaces(
      item.sectorEmissionPercentage * 100,
      2
    )
  })

  // aggregate home appliances data
  const totalAmount = dataToSet
    .filter(
      (item) =>
        item.sector === "electronicAppliance" ||
        item.sector === "domesticAppliances" ||
        item.sector === "otherExclDomestic"
    )
    .reduce((value: number, item) => item.sectorEmissionAmountAvg + value, 0)
  const totalPercentage = dataToSet
    .filter(
      (item) =>
        item.sector === "electronicAppliance" ||
        item.sector === "domesticAppliances" ||
        item.sector === "otherExclDomestic"
    )
    .reduce((value: number, item) => item.sectorEmissionPercentage + value, 0)
  dataToSet.push({
    category: "lifestyle",
    sector: "appliances",
    sectorEmissionAmountAvg: roundWithDecimalPlaces(totalAmount, 2),
    sectorEmissionPercentage: roundWithDecimalPlaces(totalPercentage, 2),
    categoryEmissionAmountAvg: dataToSet.some(
      (item) => item.category === "lifestyle"
    )
      ? dataToSet.find((item) => item.category === "lifestyle")!
          .categoryEmissionAmountAvg
      : 0,
  })

  // aggregate clothes and shoes data
  if (
    dataToSet.some((item) => item.sector === "clothes") &&
    dataToSet.some((item) => item.sector === "shoes")
  ) {
    const clothesTotalAmount =
      dataToSet.find((item) => item.sector === "clothes")!
        .sectorEmissionAmountAvg +
      dataToSet.find((item) => item.sector === "shoes")!.sectorEmissionAmountAvg
    const clothesTotalPercentage =
      dataToSet.find((item) => item.sector === "clothes")!
        .sectorEmissionPercentage +
      dataToSet.find((item) => item.sector === "shoes")!
        .sectorEmissionPercentage
    dataToSet.find(
      (item) => item.sector === "clothes"
    )!.sectorEmissionAmountAvg = roundWithDecimalPlaces(clothesTotalAmount, 2)
    dataToSet.find(
      (item) => item.sector === "clothes"
    )!.sectorEmissionPercentage = roundWithDecimalPlaces(
      clothesTotalPercentage,
      2
    )
    const indexToRemove = dataToSet.findIndex((item) => item.sector === "shoes")
    dataToSet.splice(indexToRemove, 1)
  }

  return dataToSet.filter((item) => item.sector)
}
